import {Box, Chip, styled, Theme, Typography, useTheme} from "@mui/material";
import {getParamName, viewabilityOptions} from "application/utils/dsp.utils";
import {moneyFormatter} from "application/utils/formatters.utils";
import {providerIconList} from "assets/providerIcons";
import {AppIcon} from "components/ui/AppIcon";
import moment from "moment";
import {jsonDecodeArray} from "pages/AudienceBuilder/audienceBuilderUtils";
import {AppTip} from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import styledOld from "styled-components";
import {isArray} from "xstate/lib/utils";
import {profilesFormatter} from "../../../_configuration/formaters";
import {objectApi} from "../../../application/entities/dataApi";
import {IAudiences} from "../../../application/entities/dataTypes/audiences";
import {dataUtils} from "../../../application/utils/dataState.utils";
import {TagListMini} from "../../../components/organisms/TagListMini";
import {GridMiddle, H4} from "../../../components/ui/AppElements";

interface IAudienceCardProps {
    audience: IAudiences;
    activeTab?: "TRANSPARENCY" | "ACTIVATION" | string;
    menuMode?: "transparency" | "channels" | "enablement" | undefined;
}

export const AudienceCard = (props: IAudienceCardProps) => {
    const {audience, menuMode, activeTab} = props;
    const audienceApi = new objectApi.audiences();
    const {t} = useTranslation();
    const theme = useTheme();

    const [audienceData, setAudienceData] = React.useState<IAudiences>(audience);
    const [isLoading, setIsLoading] = React.useState<Boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<"TRANSPARENCY" | "ACTIVATION" | string>(activeTab ?? "TRANSPARENCY");
    const currency = audienceData.displayCurrency;

    const lastAudienceSize =
        audienceData.lastAudienceSize === -1 || audienceData.lastAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audienceData.lastAudienceSize)} profiles`;
    const lastAuthenticatedAudienceSize =
        audienceData.lastAuthenticatedAudienceSize === -1 || audienceData.lastAuthenticatedAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audienceData.lastAuthenticatedAudienceSize)}`;
    const lastUniversalIdAudienceSize =
        audienceData.lastUniversalIdAudienceSize === -1 || audienceData.lastUniversalIdAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audienceData.lastUniversalIdAudienceSize)}`;

    const purD = new Date(audienceData.purchaseTime);
    moment(new Date(purD)).format("DD-MMM-YYYY");
    const refR = audienceData.purchaseTime ? moment(new Date(purD)).format("DD MMM YYYY") : "";

    // find audienceFeature : CERTAIN
    const certainVal = dataUtils.getObjectItemById(audienceData?.audienceFeatures ?? [], "CERTAIN", "feature.code")[0]?.featureValues?.[0];
    let geoVal = dataUtils.getObjectItemById(audienceData?.audienceFeatures ?? [], "LOCATION", "feature.code")[0]?.featureValues;

    if (geoVal) {
        try {
            geoVal = jsonDecodeArray(geoVal);
        } catch (e) {
        }
    }

    const cpmMin = audienceData.cpmMinConverted
        ? `${moneyFormatter(audienceData.cpmMinConverted ?? 0, {currency: currency}) ?? undefined}`
        : undefined;
    const cpmMax = audienceData.cpmMaxConverted
        ? `${moneyFormatter(audienceData.cpmMaxConverted ?? 0, {currency: currency}) ?? undefined}`
        : undefined;

    const cpm = audienceData.cpmMinConverted === audienceData.cpmMaxConverted ? cpmMin : `${cpmMin ?? moneyFormatter(0, {currency: currency})} - ${cpmMax}`;
    const cpmKey = audienceData.cpmMinConverted === audienceData.cpmMaxConverted ? "CPM" : "CPM Range";

    const kOv = [
        {key: "Audience ID", value: isLoading ? "..." : audienceData.id, datacy: "tc-audience-id"},
        {key: "Audience Name", value: isLoading ? "..." : audienceData.name, datacy: "tc-audience-name"},
        {
            key: "Associated Tags",
            value: isLoading ? "..." :
                <TagListMini style={{justifyContent: "end"}} list={toTaglList(audienceData.tags, "name")}/>,
            datacy: "tc-audience-tags",
        },
        {key: "Unique Profiles", value: isLoading ? "..." : lastAudienceSize, datacy: "tc-audience-size"},
        /*...(audienceData.audienceType?.code === "FIRST_PARTY" ? [{
            key: "Enhanced Profiles",
            value: isLoading ? "..." : profilesFormatter.format(Number(audienceData.lastAuthenticatedAudienceSize) + Number(audienceData.lastUniversalIdAudienceSize))
        }] : []), */
        {
            key: "Deterministic IDs (ie. Hashed Emails)",
            value: isLoading ? "..." : lastAuthenticatedAudienceSize,
            datacy: "tc-deterministicIds-size",
            subLine: true,
        },
        {
            key: "Universal IDs (ie. UID2.0, RampID)",
            value: isLoading ? "..." : lastUniversalIdAudienceSize,
            datacy: "tc-universalId-size",
            subLine: true,
        },

        {key: cpmKey, value: isLoading ? "..." : cpm, datacy: "tc-cpm"},
    ];

    if (audienceData?.audienceType?.code !== "TRAVEL") {
        // CMVP-1532
        kOv.push({
            key: "Degree of Certainty",
            value: certainVal === "0" || certainVal === undefined ? "Not Applied" : "Applied",
            datacy: "tc_deg_certainty",
        });
        kOv.push({key: "Geographies", value: geoVal?.length ?? "All Geographies", datacy: "tc-geographies"}); // CMVP-1549)
    }

    const kAud = [
        {
            key: "Declared and observed data",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.deterministicPercentage + "%",
            datacy: "tc-observed-data",
        },
        {
            key: "Inferred Data",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : 100 - audienceData.deterministicPercentage + "%",
            datacy: "tc-infered-data",
        },
        {
            key: "Data recency 7 days or less",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.recency0to7daysPercentage + "%",
            datacy: "tc-recency-0-7",
        },
        {
            key: "Data recency 8 days to 14 days",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.recency8to14daysPercentage + "%",
            datacy: "tc-recency-8-14",
        },
        {
            key: "Data recency 15 days to 30 days",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.recency15to30daysPercentage + "%",
            datacy: "tc-recency-15-30",
        },
        {
            key: "Data recency 31 days to 60 days",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.recency31to60daysPercentage + "%",
            datacy: "tc-recency-31-60",
        },
        {
            key: "Data recency 61 days to 90 days",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.recency61to90daysPercentage + "%",
            datacy: "tc-recency-61-90",
        },
        {
            key: "Data recency 90 days or more",
            value: isLoading ? "..." : lastAudienceSize === "< Minimum" ? "no data available" : audienceData.recency90andmoredaysPercentage + "%",
            datacy: "tc-recency-90",
        },
        {
            key: "Refresh Date",
            value: isLoading ? "..." : audienceData.audienceStatus === "DRAFT" ? "Daily" : refR,
            datacy: "tc-refresh-rate",
        },
    ];

    const displayAudienceDetails = () => {
        if (audience.audienceType?.id !== 5) return true;

        if (audience.audienceFeatures.length === 0) return true;

        //check that the audience has inclusion_list
        const inclusionLists = audience.audienceFeatures.filter((a) => a.feature.code === "INCLUSION_LIST");
        if (inclusionLists?.length === 0) return true;

        let display = false;
        inclusionLists.forEach((audienceFeature) => {
            audienceFeature.featureValues.forEach((feature) => {
                const featuresValues = JSON.parse(feature);
                if (featuresValues.useEnrichedProfiles === true) {
                    display = true;
                }
            });
        });
        return display;
    };

    const fkIn: any[] = Object.keys(audienceData?.industryContributions ?? {}).map((contrib: string) => {
        return {key: contrib, value: audienceData.industryContributions[contrib] + "%"};
    });

    const tabs = [
        {id: 1, text: `Transparency`, value: `TRANSPARENCY`},
        {id: 2, text: `Enablement`, value: `ACTIVATION`, disabled: audienceData.audienceStatus !== "ACTIVATED"},
    ];

    React.useEffect(() => {
        if (audience.id) {
            if (audience.audienceStatus !== "ACTIVATED") {
                setSelectedTab("TRANSPARENCY");
            }
            setIsLoading(true);
            audienceApi
                .openAndRefreshAudience(audience.id)
                .then((res) => {
                    if (res.data) setAudienceData(res.data);
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                });
        }
    }, [audience]);

    React.useEffect(() => {
        if (menuMode) {
            if (menuMode === "transparency") {
                setSelectedTab("TRANSPARENCY");
            }
            if (menuMode === "enablement") {
                setSelectedTab("ACTIVATION");
            }
        }
    }, [menuMode]);

    function toTaglList(list: any[], field: string) {
        return (list ?? [])?.map((listItem) => {
            return {id: listItem.id, text: listItem[field]};
        });
    }

    return (
        <Box sx={{height: "100%", overflow: "auto", minWidth: "350px"}}>
            {/*{!Boolean(menuMode) && <Box sx={{p: 0.5, px: 2, position: 'sticky', marginTop: 0, top: 0, backgroundColor: theme.palette.background.default}}>
      <AppTabs activeTab={['TRANSPARENCY', 'ACTIVATION'].indexOf(selectedTab)} tabList={tabs} onClick={onTabClick}/>
    </Box>}*/}
            <br/>
            <Box sx={{px: 2}}>
                {" "}
                <Typography title={audienceData.name} variant={"h4"} noWrap={true}>
                    {audienceData.name}
                </Typography>
            </Box>
            <br/>
            <Box sx={{display: selectedTab === "TRANSPARENCY" ? "" : "none"}}>
                <Box sx={{px: 2}}>
                    <Titles>Overview</Titles>
                    {kOv.map((Aud) => {
                        return (
                            <Row key={Aud.key} paddingLeft={Aud?.subLine ? "1rem" : "0"}>
                                <Cell>{Aud.key}</Cell>
                                <CellInfo data-cy={Aud.datacy}>{Aud.value}</CellInfo>
                            </Row>
                        );
                    })}
                </Box>
                <br/>
                <Box sx={{px: 2}}>
                    <Titles>Audience Details</Titles>
                    {displayAudienceDetails() === false && <>No data available</>}
                    {displayAudienceDetails() === true &&
                        kAud.map((Aud) => {
                            return (
                                <Row key={Aud.key}>
                                    <Cell>{Aud.key}</Cell>
                                    <CellInfo data-cy={Aud.datacy} title={Aud.value}>
                                        {Aud.value}
                                    </CellInfo>
                                </Row>
                            );
                        })}
                </Box>
                <br/>
                <Box sx={{px: 2}} data-cy={"industry-contributions"}>
                    <Titles>Data Industry Sources</Titles>
                    {isLoading
                        ? "..."
                        : !fkIn || fkIn.length === 0
                            ? "No sources available"
                            : fkIn.map((Aud) => {
                                return (
                                    <Row key={Aud.key}>
                                        <Cell>{Aud.key}</Cell>
                                        <CellInfo data-cy={Aud.key}>{Aud.value}</CellInfo>
                                    </Row>
                                );
                            })}
                </Box>
            </Box>
            <Box data-cy={"tc-enablement"} sx={{display: selectedTab === "ACTIVATION" ? "" : "none"}}>
                <Box sx={{px: 2}}>
                    <Titles>Enablement details</Titles>
                    <Row>
                        <Cell>Audience ID</Cell>
                        <CellInfo data-cy={"audience-id"}>{audienceData.id} </CellInfo>
                    </Row>
                    <Row>
                        <Cell>Audience name</Cell>
                        <CellInfo data-cy={"audience-name"}>{audienceData.name} </CellInfo>
                    </Row>
                    {audienceData?.requester && (
                        <Row>
                            <Cell>Requested by</Cell>
                            <CellInfo data-cy={"audience-requestedby"}>
                                {audienceData.requester.firstName} {audienceData.requester.lastName}
                            </CellInfo>
                        </Row>
                    )}
                    {audienceData?.requestApprovalTime && (
                        <Row>
                            <Cell>Request date</Cell>
                            <CellInfo
                                data-cy={"audience-request_date"}>{moment(audienceData.requestApprovalTime).format("D-MMM-YYYY h:mm A")} </CellInfo>
                        </Row>
                    )}

                    {audienceData?.approver && (
                        <Row>
                            <Cell>Approved by</Cell>
                            <CellInfo data-cy={"audience-requestedby"}>
                                {audienceData.approver.firstName} {audienceData.approver.lastName}
                            </CellInfo>
                        </Row>
                    )}
                    {audienceData?.approvalTime && (
                        <Row>
                            <Cell>Approval date</Cell>
                            <CellInfo
                                data-cy={"audience-approval_date"}>{moment(audienceData.approvalTime).format("D-MMM-YYYY h:mm A")} </CellInfo>
                        </Row>
                    )}

                    {audienceData?.activatedByUser?.firstName && (
                        <Row>
                            <Cell>Enabled by</Cell>
                            <CellInfo data-cy={"audience-enabledby"}>
                                {audienceData.activatedByUser?.firstName} {audienceData.activatedByUser?.lastName}
                            </CellInfo>
                        </Row>
                    )}
                </Box>
                {Boolean(audienceData.audienceActivationRecap) &&
                    audienceData.audienceActivationRecap.map((audienceRecap) => {
                        // Enablement time should be accurate when the enablement status = Pending
                        const act = new Date(audienceRecap.activationTime);
                        return (
                            <>
                                <DspTitle>
                                    <div className={"iconCircle"}>
                                        <AppIcon fontSize={"small"} icon={providerIconList?.[audienceRecap.dspCode]}/>
                                    </div>
                                    {audienceRecap.dspName}
                                </DspTitle>
                                <Box sx={{p: 2}}>
                                    <Row>
                                        <Cell>Enablement Date and Time</Cell>
                                        <CellInfo data-cy={"audience-activationTime"}>
                                            {Boolean(audienceRecap.activationTime)
                                                ? new Intl.DateTimeFormat("en-US", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                }).format(act)
                                                : "..."}
                                        </CellInfo>
                                    </Row>
                                    <Row>
                                        <Cell>Enablement Status</Cell>
                                        <CellInfo
                                            data-cy={"audience-enablement-status"}>{audienceRecap.activationStatus}</CellInfo>
                                    </Row>
                                    {audienceRecap?.campaignEndDate && <Row>
                                        <Cell>End Date</Cell>
                                        <CellInfo
                                            data-cy={"audience-endDate"}>{new Intl.DateTimeFormat("en-US", {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",

                                        }).format(new Date(audienceRecap.campaignEndDate))}</CellInfo>
                                    </Row>}
                                    {audienceRecap?.dealId && (
                                        <Row>
                                            <Cell>Deal ID</Cell>
                                            <CellInfo
                                                data-cy={"audience-dealId-status"}>{audienceRecap.dealId}</CellInfo>
                                        </Row>
                                    )}
                                    <Row>
                                        <Cell>Remaining Days (until expiry)</Cell>
                                        <CellInfo
                                            data-cy={"audience-remaining-days"}>{audienceRecap.expirationDaysRemaining}</CellInfo>
                                    </Row>
                                    {/* <Row>
              <Cell>
                Campaign Name
              </Cell>
              <CellInfo data-cy={'audience-campaign-name'}>
                {audienceRecap.campaignName}
              </CellInfo>
            </Row> */}
                                    <Row>
                                        <Cell>Profiles Number</Cell>
                                        <CellInfo
                                            data-cy={"audience-profiles-number"}>{profilesFormatter.format(audienceRecap.profilesNumber)}</CellInfo>
                                    </Row>
                                    <Row>
                                        <Cell>CPM</Cell>
                                        <CellInfo
                                            data-cy={"audience-cpm"}>{moneyFormatter(audienceRecap.cpmConverted, {currency: currency})}</CellInfo>
                                    </Row>
                                    {audienceRecap.pricePaidConverted > 0 && (
                                        <Row>
                                            <Cell>Spend</Cell>
                                            <CellInfo
                                                data-cy={"audience-spent"}>{moneyFormatter(audienceRecap.pricePaidConverted, {currency: currency})}</CellInfo>
                                        </Row>
                                    )}
                                    {audienceRecap?.checkoutParameters && audienceRecap?.checkoutParameters?.length > 2 && (
                                        <>
                                            <Row>
                                                <Cell style={{fontWeight: "800", flexGrow: "2"}}>Activation
                                                    parameters</Cell>
                                            </Row>
                                            <DisplayCheckoutParmameters
                                                checkoutParameters={audienceRecap.checkoutParameters}/>
                                        </>
                                    )}
                                </Box>
                            </>
                        );
                    })}
            </Box>
        </Box>
    );
};

const DisplayCheckoutParmameters = (props: { checkoutParameters: string | undefined }) => {
    if (!props.checkoutParameters) return <></>;
    const params = [];
    for (const [key, value] of Object.entries(JSON.parse(props.checkoutParameters))) {
        params.push({
            name: key,
            value: value,
        });
    }

    return (
        <>
            {params.map((param) => {
                if (!param.value) return null;
                let value: string | boolean | string[] | ReactNode;
                if (typeof param.value === "string" && param.name === "min_viewability")
                    value = viewabilityOptions.find((option) => option.value === param.value)?.label || param.value;
                else if (["include_domains", "exclude_domains"].includes(param.name)) value = (param.value as string)?.split("/").pop()?.replaceAll("_", " ");
                else if (typeof param.value === "string") value = param.value;
                else if (typeof param.value === "boolean") value = param.value ? "Yes" : "No";
                else if (isArray(param.value))
                    value = (
                        <AppTip
                            text={param.value
                                .map((item) => {
                                    return item;
                                })
                                .flat()
                                .toString()
                                .replaceAll(",", "\n")}
                        >
                            <span style={{cursor: "pointer", color: "#00B5E2"}}>{`${param.value.length} size(s)`}</span>
                        </AppTip>
                    );
                else value = "";

                return (
                    <Row key={param.name} style={{marginLeft: "0.5rem"}}>
                        <Cell>{getParamName(param.name)}</Cell>

                        <CellInfo>{value}</CellInfo>
                    </Row>
                );
            })}
        </>
    );
};

const StyledChip = styled(Chip)(({theme}) => ({
    fontSize: "10px",
    height: "19px",
    margin: "2px",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
}));

const Titles = styled(H4)((props: { theme: Theme }) => ({
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "1px solid " + props.theme.skin.borderColor,
    marginBottom: "1rem",
}));

const SubTitles = styled(H4)((props: { theme: Theme }) => ({
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "0.5rem",
}));

const Row = styledOld(GridMiddle)((props: { paddingLeft?: string }) => ({
    minHeight: "auto",
    marginBottom: "2px",
    paddingLeft: props?.paddingLeft ? props.paddingLeft : "0rem",
}));

const Cell = styledOld(Box)((props) => ({
    padding: 1,
    color: "#7587A3",
    minWidth: "100px",
}));

const CellInfo = styledOld(Box)((props) => ({
    minWidth: "42px",
    overflow: "hidden",
    padding: 1,
    flex: "1 1 auto",
    textAlign: "right",
    display: "block",
    alignContent: "end",
    justifyContent: "right",
    justifyItems: "right",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
}));

const DspTitle = styled("div")((props: { theme: Theme }) => ({
    cursor: "pointer",
    position: "static",
    marginTop: "1rem",
    top: 0,
    height: "50px",
    fontSize: "12px",
    display: "flex",
    gap: "0.5rem",
    fontWeight: "bold",
    // backgroundColor: props.theme.palette.background.default,
    backgroundColor: "#E4EAF0",
    overflow: "hidden",
    alignItems: "center",
    padding: "0 0.7rem",
    borderBottom: "1px solid white",
}));
